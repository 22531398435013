/* ----------------------------------------------------------------------------------------------------- */
/*  @ ngx-scrollbar scrollbar overrides
/* ----------------------------------------------------------------------------------------------------- */

ng-scrollbar {
    --scrollbar-thickness: 12;
    --scrollbar-hover-thickness: 18;
    --scrollbar-offset: 0;
    --scrollbar-track-color: rgb(0 0 0 / 30%);
    --scrollbar-border-radius: 0;

    --scrollbar-button-color: var(--scrollbar-thumb-color);
    --scrollbar-button-hover-color: var(--scrollbar-button-color);
    --scrollbar-button-active-color: var(--scrollbar-button-hover-color);

    // Scrollbar button arrow fill
    --scrollbar-button-fill: white;
    --scrollbar-button-hover-fill: var(--scrollbar-button-fill);
    --scrollbar-button-active-fill: var(--scrollbar-button-hover-fill);

    height: calc(100% - 60px);
}

/* Light Mode Scrollbar */
.ng-scrollbar {
    --scrollbar-thumb-color: var(--fuse-accent-600);
    --scrollbar-track-color: rgba(0, 0, 0, 0.3);
}

/* Dark Mode Scrollbar */
.dark .ng-scrollbar {
    --scrollbar-thumb-color: rgba(255, 255, 255, 0.2);
    --scrollbar-track-color: var(--fuse-accent);
}
